.row {
  &--product-options {
    &:not(:first-child) {
      margin-top: 10px;
    }

    &--with-delete {
      > *:last-child {
        max-width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--emoticon {
      > *:first-child {
        max-width: 80px;
      }

      > *:nth-child(2) {
        max-width: 80px;
      }
    }
  }
}

.sortable-row {
  > *:first-child {
    max-width: 50px;
  }

  &__pointer {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    cursor: grab;

    &--dragging {
      cursor: grabbing;
    }
  }
}

.form-color-picker {
  position: relative;
  display: inline-block;

  &__selector {
    width: 24px;
    height: 24px;
    border: 1px solid #dedede;
    cursor: pointer;
  }

  &__picker-container {
    position: absolute;
    z-index: 5;
    top: 28px;
  }
}

.custom-toggle {
  &--type-2 {
    width: 80px;

    .custom-toggle-slider::after {
      right: 4px;
    }

    input:checked + .custom-toggle-slider::before {
      transform: translateX(56px);
    }

    input:checked + .custom-toggle-slider::after {
      left: 6px;
    }
  }
}

.table-action {
  .custom-toggle__loader {
    color: #adb5bd;
  }
}

button.table-action {
  border: none;
  background-color: transparent;
}

.uploader-preview {
  margin-bottom: 14px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    max-width: 120px;
    border-radius: 6px;
    border: 1px solid #dedede;
    padding: 5px;
  }
}

.uploader-loading {
  border-radius: 6px;
  border: 1px solid #dedede;
  padding: 10px;
  min-height: 70px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.response-table {
  .table {
    border: 1px solid #ddd;
    height: 600px;
    font-size: 0.9rem;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 8px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .footer {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.sticky {
      overflow: scroll;

      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
}

.card-body {
  &--list-questions {
    position: relative;

    &__loader {
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
    }
  }
}

.question-tooltip {
  max-width: 600px;
}

.form-control {
  color: #222222;

  &:focus {
    color: #222222;
  }
}

.multi-checkbox {
  max-height: 200px;
  overflow: auto;
  border: 1px solid #dedede;
  padding-left: 10px;
  padding-right: 10px;
}

.uppy-FileInput-container {
  margin-bottom: 0 !important;
}

.bg-gradient-black {
  background: linear-gradient(87deg, #000, #595260) !important;
}
