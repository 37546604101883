//
// Table actions
//

.table-action {
  font-size: $font-size-sm;
  color: $table-action-color;
  padding: 0;
  margin-left: .6rem;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: darken($table-action-color, 10%);
  }
}

.table-action-delete {
  &:hover {
    color: theme-color("danger");
  }
}

.table-dark {
  .table-action {
    color: $table-dark-action-color;
  }
}